import Group85 from "./Group85.png";
import Group89 from "./Group89.png";
import Group95 from "./Group95.png";
import Group96 from "./Group96.png";
import Hospitals from "./Hospitais.png";
import Hospital from "./Hospital.png";
import Image1 from "./Image1.png";
import Image10 from "./Image10.png";
import Image11 from "./Image11.png";
import Image12 from "./Image12.png";
import Image13 from "./Image13.png";
import Image15 from "./Image15.png";
import Image2 from "./Image2.png";
import Image20 from "./Image20.png";
import Image21 from "./Image21.png";
import Image23 from "./Image23.png";
import Image24 from "./Image24.png";
import Image25 from "./Image25.png";
import Image26 from "./Image26.png";
import Image28 from "./Image28.png";
import Image29 from "./Image29.png";
import Image3 from "./Image3.png";
import Image39 from "./Image39.png";
import Image4 from "./Image4.png";
import Image40 from "./Image40.png";
import Image41 from "./Image41.png";
import Image5 from "./Image5.png";
import Image7 from "./Image7.png";
import Pill from "./Pill.png";
import Tablet from "./Tablet.png";
import Circle from "./Circle.png";
import Logo from "./logo.png";
import Share from "./share.png";
import Star from "./star.png";
import Info from "./info.png";
import C from "./C.png";
import D from "./D.png";
import G from "./G.png";
import M from "./M.png";
import Appstore from "./appstore.png";
import Playstore from "./playstore.png";
import QrcodeAndroid from "./qrcode_android.png";
import QrcodeApple from "./qrcode_apple.png";

export const Images = {
  Group85,
  Group89,
  Group95,
  Group96,
  Hospitals,
  Hospital,
  Image1,
  Image10,
  Image11,
  Image12,
  Image13,
  Image15,
  Image2,
  Image20,
  Image21,
  Image23,
  Image24,
  Image25,
  Image26,
  Image28,
  Image29,
  Image3,
  Image39,
  Image4,
  Image40,
  Image41,
  Image5,
  Image7,
  Pill,
  Tablet,
  Circle,
  Logo,
  Share,
  Star,
  Info,
  C,
  D,
  G,
  M,
  Appstore,
  Playstore,
  QrcodeAndroid,
  QrcodeApple,
};
