import React from "react";
import { bool, func } from "prop-types";

import { Text } from "../Text";
import { Images } from "~/assets/images";

import {
  Modal,
  ModalCard,
  WrapperClose,
  Close,
  WrapperBottom,
  Logo,
} from "./styles";

const Contact = ({ visible, close }) => {
  const support = "odontoguardiansapp@gmail.com";

  if (visible) {
    return (
      <Modal>
        <ModalCard>
          <WrapperClose>
            <Close onClick={close}>
              <Text textAlign="center">x</Text>
            </Close>
          </WrapperClose>

          <WrapperBottom>
            <Text marginTop={-20} textAlign="center" size={28} weight={900}>
              Fale Conosco
            </Text>

            <Text marginTop={60} size={13}>
              Utilize o endereço abaixo para enviar sua mensagem.
            </Text>
            <Text size={13}>Teremos o maior prazer em atendê-lo.</Text>
            <a href={`mailto:${support}`}>
              <Text marginTop={20} size={16} weight={900}>
                {support}
              </Text>
            </a>

            <Logo src={Images.Logo} />
          </WrapperBottom>
        </ModalCard>
      </Modal>
    );
  }
  return <></>;
};

Contact.propTypes = {
  visible: bool,
  close: func,
};

Contact.defaultProps = {
  visible: false,
  close: () => {},
};

export default Contact;
