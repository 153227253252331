import { put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrActions } from "react-redux-toastr";

import AuthActions from "../ducks/auth";
import api from "../../services/api";

export function* signIn({ email, password }) {
  try {
    const data = yield api.post("/auth", { email, password });
    yield put(AuthActions.signInSuccess(data.user));
    yield put(push("/dashboard"));
  } catch (error) {
    yield put(AuthActions.signInFailure(error));
    yield put(
      toastrActions.add({
        type: "error",
        title: "Falha no login",
        message: "Verifique seu e-mail/senha!",
      })
    );
  }
}
