import React, { useEffect, useState } from "react";
import { bool, element, number, string } from "prop-types";

import { Images } from "~/assets/images";
import { Text } from "../Text";

import { WrapperText, Icon } from "./styles";

function OpacityText({
  first,
  percentage,
  src,
  alt,
  text,
  startAnimation,
  resetPercentage,
  inColumn,
}) {
  const [animation, setAnimation] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (percentage < resetPercentage) {
      setAnimation(0);
      setActive(false);
    }
    if (percentage >= startAnimation) {
      setActive(true);
    }
  }, [percentage]);

  useEffect(() => {
    let interval = null;
    if (active && animation < 1) {
      interval = setInterval(() => {
        setAnimation((currentAnimation) => currentAnimation + 0.01);
      }, 12);
    } else if (animation === 0) {
      clearInterval(interval);
    } else if (animation === 1) {
      setAnimation(1);
      setActive(false);
    }
    return () => clearInterval(interval);
  }, [active, animation]);

  return (
    <WrapperText
      first={first}
      inColumn={inColumn}
      style={{
        opacity: animation,
      }}
    >
      <Icon src={src} alt={alt} />
      <Text
        textAlign={inColumn ? "center" : "left"}
        size={inColumn ? 18 : 16}
        weight={inColumn ? 500 : 300}
      >
        {text}
      </Text>
    </WrapperText>
  );
}

OpacityText.propTypes = {
  first: bool,
  percentage: number,
  src: element,
  alt: string,
  text: string,
  startAnimation: number,
  resetPercentage: number,
  inColumn: bool,
};

OpacityText.defaultProps = {
  first: false,
  percentage: null,
  src: Images.Info,
  alt: "Info",
  text: "Tenha informações adicionais sobre o conteúdo da manobra.",
  startAnimation: null,
  resetPercentage: null,
  inColumn: false,
};

export default OpacityText;
