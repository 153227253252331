import styled from "styled-components";
import colors from "~/styles/colors";
import { screenWidth } from "~/config/dimensions";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
`;

export const Center = styled.div`
  width: ${window.outerWidth}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperAnimatedText = styled.div`
  margin-top: ${screenWidth(80)}px;
`;

export const WrapperButton = styled.a`
  margin: ${screenWidth(60)}px;
`;
