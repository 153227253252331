import React, { useEffect, useState } from "react";
import { element, number, string, bool } from "prop-types";

import { Images } from "~/assets/images";
import { screenWidth } from "~/config/dimensions";

import { Image } from "./styles";

function AnimatedImageRight({
  horizontal,
  percentage,
  src,
  alt,
  width,
  top,
  right,
  startAnimation,
  strength,
  withoutOpacity,
  resetPercentage,
  rotate,
}) {
  const [animation, setAnimation] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (percentage < resetPercentage) {
      setAnimation(0);
      setActive(false);
    }
    if (percentage >= startAnimation) {
      setActive(true);
    }
  }, [percentage]);

  useEffect(() => {
    let interval = null;
    if (active && animation < 1) {
      interval = setInterval(() => {
        setAnimation((currentAnimation) => currentAnimation + 0.01);
      }, 10);
    } else if (animation === 0) {
      clearInterval(interval);
    } else if (animation === 1) {
      setAnimation(1);
      setActive(false);
    }
    return () => clearInterval(interval);
  }, [active, animation]);

  return (
    <Image
      width={width}
      style={{
        opacity: withoutOpacity ? 1 : animation,
        top: screenWidth(
          horizontal ? top : top + strength - animation * strength
        ),
        right: screenWidth(
          !horizontal ? right : right + strength - animation * strength
        ),
      }}
      src={src}
      alt={alt}
      rotate={rotate}
    />
  );
}

AnimatedImageRight.propTypes = {
  horizontal: bool,
  percentage: number,
  src: element,
  alt: string,
  width: number,
  top: number,
  right: number,
  startAnimation: number,
  strength: number,
  withoutOpacity: bool,
  resetPercentage: number,
  rotate: bool,
};

AnimatedImageRight.defaultProps = {
  horizontal: false,
  percentage: null,
  src: Images.Image41,
  alt: "Image 41",
  width: 280,
  top: 200,
  right: 370,
  startAnimation: null,
  strength: 180,
  withoutOpacity: false,
  resetPercentage: null,
  rotate: false,
};

export default AnimatedImageRight;
