import React from "react";
import { useHistory } from "react-router-dom";
import { Parallax } from "react-parallax";

import { Images } from "~/assets/images";
import { Icons } from "~/assets/icons";
import Hospital from "~/components/Hospital";
import AnimatedText from "~/components/AnimatedText";
import { GradientButton } from "~/components/GradientButton";
import MobileHeader from "~/components/MobileHeader";
import Social from "~/components/Social";
import { Text } from "~/components/Text/";
import colors from "~/styles/colors";
import AnimatedImage from "~/components/AnimatedImage";
import AnimatedImageRight from "~/components/AnimatedImageRight";
import Arrow from "~/components/Arrow";
import OpacityText from "~/components/OpacityText";
import { screenWidth } from "~/config/dimensions";

import {
  Container,
  Center,
  ImageBackground,
  WrapperButton,
  WrapperNetworks,
  NetworksText,
  BackgroundTop,
  BackgroundBottom,
  WhiteCircle,
  Image7,
  Calculator,
  WrapperCalculatorAnimatedText,
  Maneuvers,
  Group89,
  Cards,
  Image13,
  Medicaments,
  Image15,
  Dictionary,
  DictionaryTopGradient,
  DictionaryBottomGradient,
  Image20,
  Hospitals,
  Study,
  SOS,
  SAMU,
  Image26,
  Footer,
  Logo,
  Space,
} from "./styles";

function MobileHome() {
  const history = useHistory();
  return (
    <Container>
      <Center>
        <MobileHeader />

        <ImageBackground>
          <Text textAlign="center" weight={900} size={32}>
            Use esse aplicativo durante
          </Text>
          <Text textAlign="center" weight={900} size={32}>
            as emergências médicas em odontologia.
          </Text>

          <Text textAlign="center" weight={500} size={20} marginTop={20}>
            E também como uma ferramenta didática.
          </Text>

          <WrapperButton>
            <GradientButton onClick={() => history.push("/download")}>
              <Text size={18} color={colors.white} weight={700}>
                Baixe o app
              </Text>
            </GradientButton>
          </WrapperButton>

          <WrapperNetworks>
            <Social />

            <NetworksText>
              <Text textAlign="center" size={16} weight={500}>
                Nos siga nas redes sociais
              </Text>
              <Text textAlign="center" size={16} weight={500}>
                e fique por dentro das novidades.
              </Text>
            </NetworksText>
          </WrapperNetworks>
        </ImageBackground>

        <Parallax
          renderLayer={(percentage) => {
            return (
              <>
                <BackgroundTop>
                  <WhiteCircle
                    style={{
                      top: screenWidth(
                        percentage > 1.18 ? 435 : percentage * 200 + 200
                      ),
                    }}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image3}
                    alt="Celular 1"
                    width={350}
                    top={140}
                    left={40}
                    startAnimation={0.7}
                    strength={0}
                    resetPercentage={0.66}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image5}
                    alt="Celular 2"
                    width={350}
                    top={210}
                    left={-90}
                    startAnimation={0.74}
                    strength={0}
                    resetPercentage={0.66}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image4}
                    alt="Celular 3"
                    width={350}
                    top={220}
                    left={220}
                    startAnimation={0.76}
                    strength={0}
                    resetPercentage={0.66}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image39}
                    alt="Celular 4"
                    width={350}
                    top={410}
                    left={115}
                    startAnimation={0.87}
                    strength={0}
                    resetPercentage={0.66}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image2}
                    alt="Celular 5"
                    width={350}
                    top={470}
                    left={-15}
                    startAnimation={0.91}
                    strength={0}
                    resetPercentage={0.66}
                  />
                </BackgroundTop>
                <BackgroundBottom src={Images.Image7} />
                <Image7>
                  <AnimatedText
                    percentage={percentage}
                    titles={[
                      "Aprenda as manobras de",
                      "emergências mais",
                      "comuns no consultório.",
                    ]}
                    texts={[
                      "Esteja familiarizado com o",
                      "conteúdo do app desde a",
                      "graduação até a sua prática clínica.",
                    ]}
                    startAnimation={1.37}
                    resetPercentage={1.27}
                    withoutMargin
                    textAlign="center"
                    textWeight={500}
                  />
                </Image7>
              </>
            );
          }}
        />

        <Parallax
          renderLayer={(percentage) => {
            return (
              <Calculator>
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Group96}
                  atl="Group 96"
                  width={270}
                  top={0}
                  left={185}
                  startAnimation={0.48}
                  strength={800}
                  resetPercentage={0.48}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Group95}
                  atl="Group 95"
                  width={270}
                  top={120}
                  left={20}
                  startAnimation={0.53}
                  strength={800}
                  resetPercentage={0.48}
                />

                <WrapperCalculatorAnimatedText>
                  <AnimatedText
                    percentage={percentage}
                    titles={["Resultados rápidos com", "nossas calculadoras"]}
                    texts={[
                      "Calcule rapidamente a glicemia e",
                      "a pressão arterial sistêmica de",
                      "seu paciente, estude também",
                      "pelas tabelas de referência.",
                    ]}
                    startAnimation={0.85}
                    resetPercentage={0.71}
                    withoutMargin
                    textAlign="center"
                    textWeight={500}
                  />
                </WrapperCalculatorAnimatedText>

                <AnimatedImage
                  percentage={percentage}
                  src={Images.Image10}
                  alt="Paper 1"
                  width={117}
                  top={800}
                  left={260}
                  startAnimation={1.14}
                  strength={-300}
                  resetPercentage={1.02}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Image11}
                  alt="Paper 2"
                  width={72}
                  top={760}
                  left={370}
                  startAnimation={1.14}
                  strength={-140}
                  resetPercentage={1.02}
                />
              </Calculator>
            );
          }}
        >
          <Parallax
            renderLayer={(percentage) => {
              return (
                <Maneuvers>
                  <Group89 src={Images.Group89} />
                  <AnimatedText
                    percentage={percentage}
                    titles={[
                      "Tenha uma condução das",
                      "sequências das manobras.",
                    ]}
                    texts={[
                      "E avalie a condição clínica do seu",
                      "paciente, sua responsividade e",
                      "seus sinais vitais.",
                    ]}
                    startAnimation={0.95}
                    resetPercentage={0.84}
                    withoutMargin
                    textAlign="center"
                    textWeight={500}
                  />

                  <OpacityText
                    first
                    percentage={percentage}
                    startAnimation={1.25}
                    resetPercentage={0.84}
                    inColumn
                  />
                  <OpacityText
                    percentage={percentage}
                    src={Images.Share}
                    alt="Share"
                    text="Compartilhe todo o conteúdo do app com seus colegas em outros aplicativos."
                    startAnimation={1.32}
                    resetPercentage={0.84}
                    inColumn
                  />
                  <OpacityText
                    percentage={percentage}
                    src={Images.Star}
                    alt="Star"
                    text="Salve as informações mais relevantes para você e reveja quando quiser."
                    startAnimation={1.39}
                    resetPercentage={0.84}
                    inColumn
                  />
                </Maneuvers>
              );
            }}
          />
        </Parallax>

        <Parallax
          renderLayer={(percentage) => {
            return (
              <Cards>
                <Image13 src={Images.Image13} />
                <AnimatedImage
                  horizontal
                  percentage={percentage}
                  src={Images.Group85}
                  alt="Group 85"
                  width={450}
                  top={200}
                  left={117}
                  startAnimation={0.3}
                  strength={585}
                  withoutOpacity
                  resetPercentage={0.01}
                />
                <AnimatedText
                  percentage={percentage}
                  titles={[
                    "Uma sequência das",
                    "emergências mais",
                    "comuns do dia a dia.",
                  ]}
                  texts={[
                    "Saiba como desengasgar um",
                    "adulto, criança ou bebê e chame",
                    "o serviço de emergência direto",
                    "pelo app.",
                  ]}
                  startAnimation={0.7}
                  resetPercentage={0.4}
                  withoutMargin
                  textAlign="center"
                  textWeight={500}
                />
              </Cards>
            );
          }}
        />

        <Parallax
          renderLayer={(percentage) => {
            return (
              <Medicaments>
                <Image15 src={Images.Image15} />

                <AnimatedImage
                  percentage={percentage}
                  src={Images.Pill}
                  alt="Pill"
                  width={58}
                  top={150}
                  left={65}
                  startAnimation={0.4}
                  strength={-70}
                  withoutOpacity
                  resetPercentage={0.01}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Tablet}
                  alt="Tablet"
                  width={25}
                  top={260}
                  left={240}
                  startAnimation={0.4}
                  strength={-100}
                  withoutOpacity
                  resetPercentage={0.01}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Circle}
                  alt="Circle"
                  width={25}
                  top={200}
                  left={280}
                  startAnimation={0.4}
                  strength={-50}
                  withoutOpacity
                  resetPercentage={0.01}
                />

                <AnimatedText
                  percentage={percentage}
                  titles={[
                    "Seja notificado do",
                    "vencimento dos",
                    "medicamentos da caixa",
                    "de emergência",
                  ]}
                  texts={[
                    "Registre seus medicamentos e",
                    "receba mensagens de alerta",
                    "automaticamente e já agendadas",
                    "em seu aparelho.",
                  ]}
                  startAnimation={0.75}
                  resetPercentage={0.5}
                  withoutMargin
                  textAlign="center"
                  textWeight={500}
                />
              </Medicaments>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.quaternary.blue }}
          renderLayer={(percentage) => {
            return (
              <>
                <Dictionary>
                  <DictionaryTopGradient />
                  <Image20 src={Images.Image20} alt="Image 20" />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.C}
                    alt="C"
                    width={70}
                    top={60}
                    left={40}
                    startAnimation={0.6}
                    strength={-60}
                    withoutOpacity
                    resetPercentage={0.16}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.M}
                    alt="M"
                    width={70}
                    top={300}
                    left={75}
                    startAnimation={0.7}
                    strength={-80}
                    withoutOpacity
                    resetPercentage={0.16}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.D}
                    alt="D"
                    width={70}
                    top={120}
                    left={310}
                    startAnimation={0.7}
                    strength={-60}
                    withoutOpacity
                    resetPercentage={0.16}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.G}
                    alt="G"
                    width={70}
                    top={360}
                    left={370}
                    startAnimation={0.6}
                    strength={-120}
                    withoutOpacity
                    resetPercentage={0.16}
                  />
                </Dictionary>
                <DictionaryBottomGradient>
                  <AnimatedText
                    percentage={percentage}
                    titles={[
                      "Glossário com termos",
                      "técnicos em medicina",
                      "abordados nas manobras.",
                    ]}
                    texts={[
                      "Pesquise sem precisar sair do",
                      "aplicativo, tenha tudo o que",
                      "precisar em um único lugar.",
                    ]}
                    startAnimation={0.86}
                    resetPercentage={0.66}
                    withoutMargin
                    textAlign="center"
                    textWeight={500}
                  />
                </DictionaryBottomGradient>
              </>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.white }}
          bgImage={Images.Image21}
          bgImageAlt="Hospitals"
          bgImageStyle={{
            width: screenWidth(1080),
            marginLeft: -screenWidth(280),
            marginTop: screenWidth(150),
          }}
          strength={0}
          renderLayer={(percentage) => {
            return (
              <Hospitals>
                <Hospital
                  percentage={percentage}
                  width={85}
                  top={117.5}
                  left={70}
                  startAnimation={0.46}
                  resetPercentage={0.25}
                />
                <Hospital
                  percentage={percentage}
                  width={85}
                  top={135}
                  left={90}
                  startAnimation={0.5}
                  resetPercentage={0.25}
                />
                <Hospital
                  percentage={percentage}
                  width={85}
                  top={250}
                  left={395}
                  startAnimation={0.53}
                  resetPercentage={0.25}
                />
                <Hospital
                  percentage={percentage}
                  width={85}
                  top={260}
                  left={320}
                  startAnimation={0.58}
                  resetPercentage={0.25}
                />
                <Hospital
                  percentage={percentage}
                  width={85}
                  top={290}
                  left={210}
                  startAnimation={0.64}
                  resetPercentage={0.25}
                />
                <Hospital
                  percentage={percentage}
                  width={85}
                  top={397}
                  left={400}
                  startAnimation={0.76}
                  resetPercentage={0.25}
                />
                <Hospital
                  percentage={percentage}
                  width={85}
                  top={445}
                  left={340}
                  startAnimation={0.82}
                  resetPercentage={0.25}
                />

                <AnimatedText
                  percentage={percentage}
                  titles={["Localize os hospitais", "próximos a você."]}
                  texts={[
                    "Tenha uma rota para guia-lo",
                    "diretamente pelo próprio Google",
                    "Maps® em seu aparelho.",
                  ]}
                  startAnimation={0.9}
                  resetPercentage={0.625}
                  withoutMargin
                  textAlign="center"
                  textWeight={500}
                />
              </Hospitals>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.white }}
          strength={0}
          renderLayer={(percentage) => {
            return (
              <Study>
                <AnimatedImageRight
                  horizontal
                  percentage={percentage}
                  src={Images.Image23}
                  atl="Image 23"
                  width={600}
                  top={0}
                  right={-150}
                  startAnimation={0.15}
                  strength={-550}
                  resetPercentage={0.1}
                />
                <AnimatedImageRight
                  horizontal
                  percentage={percentage}
                  src={Icons.Ellipse}
                  atl="Image 23"
                  width={550}
                  top={165}
                  right={-220}
                  startAnimation={0.15}
                  strength={-550}
                  resetPercentage={0.1}
                  withoutOpacity
                  rotate
                />
                <AnimatedImage
                  horizontal
                  percentage={percentage}
                  src={Images.Image24}
                  atl="Image 24"
                  width={560}
                  top={330}
                  left={-50}
                  startAnimation={0.62}
                  strength={-510}
                  resetPercentage={0.49}
                />

                <AnimatedText
                  percentage={percentage}
                  titles={["Estude pelo aplicativo de", "qualquer lugar."]}
                  texts={[
                    "Uma ferramenta de ensino para",
                    "estudantes e profissionais da",
                    "área da saúde.",
                  ]}
                  startAnimation={1.1}
                  resetPercentage={0.8}
                  withoutMargin
                  textAlign="center"
                  textWeight={500}
                />
              </Study>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.white }}
          strength={0}
          renderLayer={(percentage) => {
            return (
              <SOS>
                <AnimatedImageRight
                  horizontal
                  percentage={percentage}
                  src={Images.Image25}
                  atl="Image 25"
                  width={550}
                  top={30}
                  right={0}
                  startAnimation={0.12}
                  strength={-550}
                  resetPercentage={0.28}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Image40}
                  atl="Image 40"
                  width={240}
                  top={370}
                  left={0}
                  startAnimation={0.67}
                  strength={-200}
                  resetPercentage={0.4}
                />
                <AnimatedImage
                  percentage={percentage}
                  width={240}
                  top={388}
                  left={188}
                  startAnimation={0.67}
                  resetPercentage={0.4}
                />
                <Arrow
                  percentage={percentage}
                  width={110}
                  top={515}
                  left={210}
                  startAnimation={0.77}
                  strength={180}
                  resetPercentage={0.4}
                />

                <AnimatedText
                  percentage={percentage}
                  titles={[
                    "Cadastre contatos de",
                    "emergência para pedidos",
                    "de S.O.S.",
                  ]}
                  texts={[
                    "Dispare suas mensagens de",
                    "emergência diretamente para o",
                    "WhatsApp® dos contatos que",
                    "você cadastrar.",
                  ]}
                  startAnimation={1.1}
                  resetPercentage={0.8}
                  withoutMargin
                  textAlign="center"
                  textWeight={500}
                />
              </SOS>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.white }}
          strength={0}
          renderLayer={(percentage) => {
            return (
              <SAMU>
                <AnimatedImageRight
                  horizontal
                  percentage={percentage}
                  src={Images.Image28}
                  atl="Image 28"
                  width={330}
                  top={185}
                  right={120}
                  startAnimation={0.46}
                  strength={-400}
                  resetPercentage={0.35}
                />

                <Image26 src={Images.Image26} atl="Image 26" />

                <AnimatedImageRight
                  horizontal
                  percentage={percentage}
                  src={Images.Image29}
                  atl="Image 29"
                  width={440}
                  top={185}
                  right={-95}
                  startAnimation={0.6}
                  strength={-400}
                  resetPercentage={0.35}
                />

                <AnimatedText
                  percentage={percentage}
                  titles={[
                    "Acione rapidamente um",
                    "serviço de atendimento",
                    "emergencial.",
                  ]}
                  texts={[
                    "Para as cidades que não",
                    "possuem o serviço do SAMU,",
                    "acione o 193 direto pelo app.",
                  ]}
                  startAnimation={0.86}
                  resetPercentage={0.7}
                  withoutMargin
                  textAlign="center"
                  textWeight={500}
                />

                <Footer>
                  <Logo src={Images.Logo} />

                  <Text size={20}>Uma tecnologia social acadêmica</Text>

                  <Space />

                  <Social />

                  <Text
                    textAlign="center"
                    size={15}
                    color={colors.primary.gray}
                  >
                    Copyright © 2021 Odonto Guardians. Todos os direitos
                    reservados. App Odonto Guardians CNPJ 37.466.354/0001-36
                  </Text>
                  <Text
                    textAlign="center"
                    size={15}
                    color={colors.primary.gray}
                  >
                    Aplicativo desenvolvido em parceria com a Universidade
                    Federal do Rio de Janeiro.
                  </Text>
                </Footer>
              </SAMU>
            );
          }}
        />
      </Center>
    </Container>
  );
}

export default MobileHome;
