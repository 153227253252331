import React from "react";
import { bool, func } from "prop-types";

import { Text } from "../Text";

import {
  Modal,
  ModalCard,
  WrapperClose,
  Close,
  WrapperBottom,
  Code,
  QR,
  Divider,
} from "./styles";
import { Row } from "../Row";
import { Images } from "~/assets/images";
import { GradientButton } from "../GradientButton";
import colors from "~/styles/colors";

const Download = ({ visible, close }) => {
  if (visible) {
    return (
      <Modal>
        <ModalCard>
          <WrapperClose>
            <Close onClick={close}>
              <Text textAlign="center">x</Text>
            </Close>
          </WrapperClose>

          <WrapperBottom>
            <Text marginTop={-20} textAlign="center" size={28} weight={900}>
              Aplicativo disponível nas
            </Text>
            <Text textAlign="center" size={28} weight={900}>
              principais lojas.
            </Text>

            <Text marginTop={20} textAlign="center" size={16}>
              Baixe agora no link ou através do
            </Text>
            <Text textAlign="center" size={16}>
              QR. Code
            </Text>

            <Row>
              <Code src={Images.Playstore}>
                <QR src={Images.QrcodeAndroid} alt="QR code" />

                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.odontoguardians"
                >
                  <GradientButton animated>
                    <Text size={18} color={colors.white} weight={700}>
                      Baixe o app
                    </Text>
                  </GradientButton>
                </a>
              </Code>
              <Divider />
              <Code src={Images.Appstore}>
                <QR src={Images.QrcodeApple} alt="QR code" />

                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.apple.com/us/app/odonto-guardians-app/id1576274050"
                >
                  <GradientButton animated>
                    <Text size={18} color={colors.white} weight={700}>
                      Baixe o app
                    </Text>
                  </GradientButton>
                </a>
              </Code>
            </Row>
          </WrapperBottom>
        </ModalCard>
      </Modal>
    );
  }
  return <></>;
};

Download.propTypes = {
  visible: bool,
  close: func,
};

Download.defaultProps = {
  visible: false,
  close: () => {},
};

export default Download;
