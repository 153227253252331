import React, { useState } from "react";
import { bool, func } from "prop-types";
import { Row } from "../Row";
import { GradientButton } from "~/components/GradientButton";
import { Text } from "~/components/Text";

import { Container, Logo } from "./styles";
import colors from "~/styles/colors";
import { Images } from "~/assets/images";
import Download from "../Download";
import Contact from "../Contact";

function Header({ download, setDownload }) {
  const [contact, setContact] = useState(false);

  return (
    <Container>
      <Logo src={Images.Logo} />
      <Row>
        <GradientButton onClick={() => setDownload(true)}>
          <Text size={18} color={colors.white} weight={700}>
            Baixe o app
          </Text>
        </GradientButton>
        <GradientButton none marginRigth={150} onClick={() => setContact(true)}>
          <Text size={18}>Fale conosco</Text>
        </GradientButton>
      </Row>

      <Download visible={download} close={() => setDownload(false)} />
      <Contact visible={contact} close={() => setContact(false)} />
    </Container>
  );
}

Header.propTypes = {
  download: bool,
  setDownload: func,
};

Header.defaultProps = {
  download: false,
  setDownload: () => {},
};

export default Header;
