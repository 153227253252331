import axios from "axios";

const api = axios.create({
  baseURL: "https://cofarja-app.pogoenterprise.com.br",
});

export const apiIBGE = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/api/v1/",
});

api.interceptors.response.use(
  (response) => {
    return response;
  },

  async function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("persist:@cofarja");
    }
    return Promise.reject(error);
  }
);

export default api;
