import React from "react";
import { Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { isMobile } from "react-device-detect";

import history from "./history";
import Guest from "./guest";

import Home from "~/pages/Home";
import MobileHome from "~/pages/MobileHome";
import Contact from "~/pages/Contact";
import Download from "~/pages/Download";

const Routes = () => {
  if (isMobile) {
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Guest component={MobileHome} exact path="/" />
          <Guest component={Contact} exact path="/contact" />
          <Guest component={Download} exact path="/download" />
        </Switch>
      </ConnectedRouter>
    );
  }
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Guest component={Home} exact path="/" />
      </Switch>
    </ConnectedRouter>
  );
};

export default Routes;
