import styled from "styled-components";
import colors from "~/styles/colors";
import { screenWidth } from "~/config/dimensions";

export const Container = styled.div`
  width: 100%;
  height: ${screenWidth(90)}px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${colors.white};
  box-shadow: 0px ${screenWidth(3)}px ${screenWidth(15)}px #00000010;
`;

export const Logo = styled.img`
  width: ${screenWidth(150)}px;
  margin: ${screenWidth(150)}px;
`;
