import React from "react";
import { Provider } from "react-redux";

import store from "./store";
import Routes from "./routes";
import Global from "~/styles/global";

function App() {
  return (
    <>
      <Provider store={store}>
        <Routes />
        <Global />
      </Provider>
    </>
  );
}

export default App;
