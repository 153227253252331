import styled from "styled-components";
import { Icons } from "~/assets/icons";
import colors from "~/styles/colors";
import { screenWidth } from "~/config/dimensions";
import { Images } from "~/assets/images";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.secondary.blue};
  display: flex;
  justify-content: center;
`;

export const Center = styled.div`
  width: ${window.outerWidth}px;
`;

export const ImageBackground = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${screenWidth(100)}px;
  background-image: url("${Icons.Rectangle40}");
  background-position-x: center;
  background-size: 100%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding: 0px ${screenWidth(40)}px;
`;

export const WrapperButton = styled.div`
  margin: ${screenWidth(60)}px;
`;

export const WrapperNetworks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${screenWidth(30)}px;
`;

export const NetworksText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${screenWidth(10)}px;
  padding-left: ${screenWidth(10)}px;
`;

export const BackgroundTop = styled.div`
  background-image: url("${Images.Image1}");
  background-position-x: ${-screenWidth(540)}px;
  background-size: ${screenWidth(1000)}px;
  background-repeat: no-repeat;
  width: ${window.outerWidth}px;
  height: ${screenWidth(760)}px;
`;

export const BackgroundBottom = styled.img`
  width: ${screenWidth(800)}px;
`;

export const WhiteCircle = styled.div`
  background-color: ${colors.white};
  border-radius: ${screenWidth(1000)}px;
  position: absolute;
  width: ${screenWidth(300)}px;
  height: ${screenWidth(300)}px;
`;

export const Image7 = styled.div`
  height: ${screenWidth(300)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Calculator = styled.div`
  height: ${screenWidth(710)}px;
  display: flex;
  align-items: flex-end;
`;

export const WrapperCalculatorAnimatedText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const Maneuvers = styled.div`
  height: ${screenWidth(1200)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const Group89 = styled.img`
  position: absolute;
  width: ${screenWidth(650)}px;
  top: ${screenWidth(200)}px;
  left: -${screenWidth(245)}px;
`;

export const Cards = styled.div`
  height: ${screenWidth(730)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Image13 = styled.img`
  position: absolute;
  width: ${screenWidth(360)}px;
  top: ${screenWidth(40)}px;
  left: ${screenWidth(-10)}px;
`;

export const Medicaments = styled.div`
  height: ${screenWidth(800)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Image15 = styled.img`
  position: absolute;
  top: 0px;
  left: ${screenWidth(20)}px;
  width: ${screenWidth(500)}px;
`;

export const Dictionary = styled.div`
  height: ${screenWidth(500)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const DictionaryTopGradient = styled.div`
  position: absolute;
  top: 0px;
  width: ${window.outerWidth}px;
  height: ${screenWidth(100)}px;
  background: linear-gradient(
    0deg,
    ${colors.quaternary.blue} 0%,
    ${colors.secondary.blue} 100%
  );
`;

export const DictionaryBottomGradient = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0px;
  width: ${window.outerWidth}px;
  height: ${screenWidth(370)}px;
  background: linear-gradient(
    0deg,
    ${colors.white} 0%,
    ${colors.tertiary.blue} 100%
  );
`;

export const Image20 = styled.img`
  position: absolute;
  top: ${screenWidth(100)}px;
  left: ${-screenWidth(10)}px;
  width: ${screenWidth(500)}px;
`;

export const Hospitals = styled.div`
  height: ${screenWidth(800)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Study = styled.div`
  height: ${screenWidth(950)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const SOS = styled.div`
  height: ${screenWidth(1050)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const SAMU = styled.div`
  height: ${screenWidth(1200)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Image26 = styled.img`
  position: absolute;
  top: ${screenWidth(50)}px;
  right: ${screenWidth(150)}px;
  width: ${screenWidth(250)}px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.secondary.gray};
  margin: ${screenWidth(50)}px ${screenWidth(40)}px 0px ${screenWidth(40)}px;
  padding: ${screenWidth(100)}px 0px ${screenWidth(20)}px 0px;
`;

export const Logo = styled.img`
  width: ${screenWidth(150)}px;
  margin-bottom: ${screenWidth(10)}px;
`;

export const Space = styled.div`
  width: ${screenWidth(220)}px;
  height: ${screenWidth(20)}px;
`;
