import Ellipse from "./Ellipse.svg";
import Group82 from "./Group82.svg";
import Group83 from "./Group83.svg";
import Group84 from "./Group84.svg";
import Rectangle40 from "./Rectangle40.svg";
import Arrow from "./Arrow.svg";
import Setup from "./Setup.svg";

export const Icons = {
  Ellipse,
  Group82,
  Group83,
  Group84,
  Rectangle40,
  Arrow,
  Setup,
};
