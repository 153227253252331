import styled from "styled-components";
import { screenWidth } from "~/config/dimensions";

export const Image = styled.img`
  position: absolute;
  width: ${(props) => screenWidth(props.width)}px;
  height: ${(props) => screenWidth(props.height)}px;
  top: ${(props) => screenWidth(props.top)}px;
  left: ${(props) => screenWidth(props.left)}px;
`;
