import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Images } from "~/assets/images";
import { Icons } from "~/assets/icons";
import { Text } from "../Text";

import {
  Container,
  Space,
  Logo,
  Button,
  Setup,
  Modal,
  ModalCard,
  ModalItem,
} from "./styles";

function MobileHeader() {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  function navigate(route) {
    history.push(route);
    setVisible(false);
  }

  return (
    <>
      {visible && (
        <Modal>
          <ModalCard>
            <ModalItem onClick={() => navigate("/")}>
              <Text textAlign="center" size={20} weight={500}>
                Início
              </Text>
            </ModalItem>

            <ModalItem onClick={() => navigate("/contact")}>
              <Text textAlign="center" size={20} weight={500}>
                Fale conosco
              </Text>
            </ModalItem>

            <ModalItem last onClick={() => navigate("/download")}>
              <Text textAlign="center" size={20} weight={500}>
                Baixe o app
              </Text>
            </ModalItem>
          </ModalCard>
        </Modal>
      )}

      <Container>
        <Space />

        <Logo src={Images.Logo} />

        <Button onClick={() => setVisible((value) => !value)}>
          <Setup src={Icons.Setup} />
        </Button>
      </Container>
    </>
  );
}

export default MobileHeader;
