import styled from "styled-components";
import { screenWidth } from "~/config/dimensions";

export const GradientButton = styled.button`
  width: ${screenWidth(170)}px;
  height: ${screenWidth(40)}px;

  border-radius: ${screenWidth(7)}px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgb(50, 92, 142);

  background: ${(props) =>
    props.none
      ? "transparent"
      : "linear-gradient(90deg,rgba(50, 92, 142, 1) 0%, rgba(57, 146, 177, 1) 100%)"};
  margin-right: ${(props) =>
    screenWidth(props.marginRigth ? props.marginRigth : 0)}px;
  cursor: pointer;
  transition: ${(props) => (props.animated ? "opacity .5s" : "none")};
  opacity: ${(props) => (props.animated ? 0 : 1)};
  &:hover {
    opacity: 1;
  }
`;
