import React, { useEffect, useState } from "react";
import { element, number, string } from "prop-types";

import { Images } from "~/assets/images";

import { Image } from "./styles";

function Hospital({
  percentage,
  src,
  alt,
  width,
  top,
  left,
  startAnimation,
  resetPercentage,
}) {
  const [animation, setAnimation] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (percentage < resetPercentage) {
      setAnimation(0);
      setActive(false);
    }
    if (percentage >= startAnimation) {
      setActive(true);
    }
  }, [percentage]);

  useEffect(() => {
    let interval = null;
    if (active && animation < 1) {
      interval = setInterval(() => {
        setAnimation((currentAnimation) => currentAnimation + 0.1);
      }, 22);
    } else if (animation === 0) {
      clearInterval(interval);
    } else if (animation === 1) {
      setAnimation(1);
      setActive(false);
    }
    return () => clearInterval(interval);
  }, [active, animation]);

  return (
    <Image
      width={width}
      top={top}
      left={left}
      style={{
        transform: `scale(${animation})`,
      }}
      src={src}
      alt={alt}
    />
  );
}

Hospital.propTypes = {
  percentage: number,
  src: element,
  alt: string,
  width: number,
  top: number,
  left: number,
  startAnimation: number,
  resetPercentage: number,
};

Hospital.defaultProps = {
  percentage: null,
  src: Images.Hospital,
  alt: "Hospital",
  width: 170,
  top: 0,
  left: 0,
  startAnimation: null,
  resetPercentage: null,
};

export default Hospital;
