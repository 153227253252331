import styled from "styled-components";
import colors from "~/styles/colors";
import { screenWidth } from "~/config/dimensions";

export const Text = styled.p`
  color: ${(props) => (props.color ? props.color : colors.primary.blue)};
  font-size: ${(props) => screenWidth(props.size ? props.size : 20)}px;
  font-family: "museo-sans", sans-serif;
  font-weight: ${(props) => (props.weight ? props.weight : "300")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin-top: ${(props) =>
    screenWidth(props.marginTop ? props.marginTop : 0)}px;
`;
