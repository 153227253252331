import styled from "styled-components";
import { screenWidth } from "~/config/dimensions";

export const WrapperText = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.inColumn ? "column" : "row")};
  align-items: center;
  width: ${screenWidth(340)}px;
  margin: ${(props) => (props.first ? screenWidth(80) : screenWidth(30))}px
    ${(props) => (props.inColumn ? 0 : screenWidth(100))}px 0px 0px;
`;

export const Icon = styled.img`
  width: ${screenWidth(35)}px;
  height: ${screenWidth(35)}px;
  margin: ${screenWidth(10)}px ${screenWidth(20)}px;
`;
