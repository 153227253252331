import styled from "styled-components";
import colors from "~/styles/colors";
import { screenWidth } from "~/config/dimensions";

export const Container = styled.div`
  width: 100%;
  height: ${screenWidth(90)}px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${colors.white};
  box-shadow: 0px ${screenWidth(3)}px ${screenWidth(15)}px #00000010;
`;

export const Space = styled.img`
  width: ${screenWidth(65)}px;
`;

export const Logo = styled.img`
  width: ${screenWidth(150)}px;
`;

export const Button = styled.button`
  background-color: transparent;
`;

export const Setup = styled.img`
  width: ${screenWidth(35)}px;
  margin-right: ${screenWidth(30)}px;
`;

export const Modal = styled.div`
  position: absolute;
  display: flex;
  width: ${window.outerWidth}px;
  margin-top: ${screenWidth(91)}px;
`;

export const ModalCard = styled.div`
  width: ${window.outerWidth}px;
  background: ${colors.white};
  box-shadow: 0px ${screenWidth(3)}px ${screenWidth(25)}px #0000000f;
  padding: 0px ${screenWidth(40)}px;
`;

export const ModalItem = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${colors.white};
  border-bottom: ${(props) =>
    props.last ? "1pz" : `1px solid ${colors.secondary.gray}`};
  padding: ${screenWidth(20)}px;
`;
