import React from "react";

import { Icons } from "~/assets/icons";

import { Image } from "./styles";

const Social = () => {
  const social = {
    facebook: "https://www.facebook.com/odontoguardians",
    instagram: "https://www.instagram.com/odontoguardians",
    linkedin: "https://www.linkedin.com/company/odonto-guardians/",
  };

  return (
    <div>
      <a target="_blank" rel="noreferrer" href={social.facebook}>
        <Image alt="facebook" src={Icons.Group84} />
      </a>
      <a target="_blank" rel="noreferrer" href={social.instagram}>
        <Image alt="instagram" src={Icons.Group83} />
      </a>
      <a target="_blank" rel="noreferrer" href={social.linkedin}>
        <Image alt="linkedin" src={Icons.Group82} />
      </a>
    </div>
  );
};

export default Social;
