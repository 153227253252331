import { createGlobalStyle } from "styled-components";
import color from "./colors";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  html, body, #root{
    background-color: ${color.white};
    .sc-czWuxR .clXLwV {
      color: ${color.red};
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid ${color.secondary};
    }

    .MuiFormLabel-root.Mui-focused {
      color: ${color.secondary};
      outline-color: ${color.secondary};
    }

    .MuiAutocomplete-listbox{
        ::-webkit-scrollbar {
        width: 7px;
        cursor: pointer;

        border-radius: 50px;
       }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f3f3f3;
        cursor: pointer;

        border-radius: 50px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background:${color.secondary};

        border-radius: 50px;

        cursor: pointer;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${color.gray};

        border-radius: 50px;
      }
    }
    .MuiAutocomplete-popper{
      margin-top: -14px;

      background-color: ${color.whiteSecond};
    }
    .MuiAutocomplete-listbox{
      background-color: ${color.whiteSecond};

    }
    .MuiAutocomplete-noOptions{
      background-color: ${color.whiteSecond};

    }
    .MuiAutocomplete-option{
      color: ${color.gray}
    }
    .MuiAutocomplete-loading{
      background-color: ${color.whiteSecond};

    }
    .MuiMobileStepper-dot {

    background-color: ${color.graySecond}; ;
}
    .MuiMobileStepper-dotActive {
      background-color: ${color.secondary};

}
  }
  *, button, input {
    border: 0;
    outline: 0;
    font-family: 'Roboto', sans-serif;
  }

`;
