import styled from "styled-components";
import colors from "~/styles/colors";
import { screenWidth } from "~/config/dimensions";

export const Modal = styled.div`
  position: absolute;
  display: flex;
  width: ${window.outerWidth}px;
  justify-content: center;
  padding-top: ${screenWidth(600)}px;
`;

export const ModalCard = styled.div`
  background: ${colors.white};
  box-shadow: 0px ${screenWidth(3)}px ${screenWidth(25)}px #0000000f;
  border-radius: ${screenWidth(20)}px;
`;

export const WrapperClose = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Close = styled.button`
  width: ${screenWidth(40)}px;
  height: ${screenWidth(40)}px;
  margin: ${screenWidth(19)}px ${screenWidth(21)}px 0px 0px;
  border-radius: ${screenWidth(100)}px;
  background-color: ${colors.tertiary.gray};
  cursor: pointer;
`;

export const WrapperBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px ${screenWidth(65)}px 0px ${screenWidth(65)}px;
`;

export const Logo = styled.img`
  width: ${screenWidth(150)}px;
  margin: ${screenWidth(70)}px ${screenWidth(100)}px;
`;
