import styled from "styled-components";
import colors from "~/styles/colors";
import { screenWidth } from "~/config/dimensions";

export const Modal = styled.div`
  position: absolute;
  display: flex;
  width: ${window.outerWidth}px;
  justify-content: center;
  padding-top: ${screenWidth(700)}px;
`;

export const ModalCard = styled.div`
  background: ${colors.white};
  box-shadow: 0px ${screenWidth(3)}px ${screenWidth(25)}px #0000000f;
  border-radius: ${screenWidth(20)}px;
`;

export const WrapperClose = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Close = styled.button`
  width: ${screenWidth(40)}px;
  height: ${screenWidth(40)}px;
  margin: ${screenWidth(19)}px ${screenWidth(21)}px 0px 0px;
  border-radius: ${screenWidth(100)}px;
  background-color: ${colors.tertiary.gray};
  cursor: pointer;
`;

export const WrapperBottom = styled.div`
  padding: 0px ${screenWidth(65)}px ${screenWidth(55)}px ${screenWidth(65)}px;
`;

export const Code = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${screenWidth(170)}px;
  height: ${screenWidth(260)}px;
  border-radius: ${screenWidth(16)}px;
  background-color: ${colors.tertiary.gray};
  margin-top: ${screenWidth(20)}px;
  background-image: url("${(props) => props.src}");
  background-size: ${screenWidth(110)}px;
  background-repeat: no-repeat;
  background-position: ${screenWidth(30)}px ${screenWidth(180)}px;
`;

export const QR = styled.img`
  width: ${screenWidth(100)}px;
  margin-bottom: ${screenWidth(30)}px;
`;

export const Divider = styled.div`
  height: ${screenWidth(200)}px;
  border-left: ${screenWidth(2)}px solid ${colors.quaternary.gray};
  margin: ${screenWidth(30)}px;
  margin-top: ${screenWidth(50)}px;
`;
