import React from "react";

import { Images } from "~/assets/images";
import AnimatedText from "~/components/AnimatedText";
import MobileHeader from "~/components/MobileHeader";
import AnimatedImage from "~/components/AnimatedImage";

import {
  Container,
  Center,
  WrapperAnimatedText,
  WrapperButton,
  DownloadButton,
} from "./styles";

function Download() {
  return (
    <Container>
      <Center>
        <MobileHeader />

        <WrapperAnimatedText>
          <AnimatedText
            percentage={1}
            titles={["Aplicativo disponível", "nas principais lojas."]}
            texts={["Baixe agora."]}
            startAnimation={0}
            resetPercentage={2}
            withoutMargin
            textAlign="center"
            textWeight={500}
          />
        </WrapperAnimatedText>

        <WrapperButton
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.odontoguardians"
        >
          <DownloadButton src={Images.Playstore} />
        </WrapperButton>
        <WrapperButton
          target="_blank"
          rel="noreferrer"
          href="https://apps.apple.com/us/app/odonto-guardians-app/id1576274050"
        >
          <DownloadButton src={Images.Appstore} />
        </WrapperButton>

        <AnimatedImage
          horizontal
          percentage={1}
          src={Images.Image24}
          atl="Image 24"
          width={500}
          top={441}
          left={-50}
          startAnimation={0}
          strength={-500}
          resetPercentage={2}
        />
      </Center>
    </Container>
  );
}

export default Download;
