import React, { useEffect, useState } from "react";
import { element, number, string } from "prop-types";

import { Icons } from "~/assets/icons";

import { Image } from "./styles";

function Arrow({
  percentage,
  src,
  alt,
  width,
  top,
  left,
  startAnimation,
  resetPercentage,
}) {
  const [animation, setAnimation] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (percentage < resetPercentage) {
      setAnimation(0);
      setActive(false);
    }
    if (percentage >= startAnimation) {
      setActive(true);
    }
  }, [percentage]);

  useEffect(() => {
    let interval = null;
    if (active && animation < 1) {
      interval = setInterval(() => {
        setAnimation((currentAnimation) => currentAnimation + 0.01);
      }, 6);
    } else if (animation === 0) {
      clearInterval(interval);
    } else if (animation === 1) {
      setAnimation(1);
      setActive(false);
    }
    return () => clearInterval(interval);
  }, [active, animation]);

  return (
    <Image
      width={width}
      top={top}
      left={left}
      style={{
        opacity: animation,
        transform: `scale(${animation}) rotate(${180 + animation * 180}deg)`,
      }}
      src={src}
      alt={alt}
    />
  );
}

Arrow.propTypes = {
  percentage: number,
  src: element,
  alt: string,
  width: number,
  top: number,
  left: number,
  startAnimation: number,
  resetPercentage: number,
};

Arrow.defaultProps = {
  percentage: null,
  src: Icons.Arrow,
  alt: "Arrow",
  width: 130,
  top: 370,
  left: 380,
  startAnimation: null,
  resetPercentage: null,
};

export default Arrow;
