import React, { useState } from "react";
import { Parallax } from "react-parallax";
import { Icons } from "~/assets/icons";
import { Images } from "~/assets/images";
import Hospital from "~/components/Hospital";
import AnimatedText from "~/components/AnimatedText";
import { GradientButton } from "~/components/GradientButton";
import Header from "~/components/Header";
import Social from "~/components/Social";
import { Text } from "~/components/Text/";
import colors from "~/styles/colors";
import AnimatedImage from "~/components/AnimatedImage";
import AnimatedImageRight from "~/components/AnimatedImageRight";
import Arrow from "~/components/Arrow";
import OpacityText from "~/components/OpacityText";
import { screenWidth } from "~/config/dimensions";

import {
  Container,
  Center,
  ImageBackground,
  WrapperButton,
  WrapperNetworks,
  NetworksText,
  BackgroundTop,
  BackgroundBottom,
  WhiteCircle,
  Image7,
  Calculator,
  Group89,
  Cards,
  Image13,
  CardsGradient,
  WrapperAnimatedText,
  Medicaments,
  Image15,
  Dictionary,
  DictionaryTopGradient,
  DictionaryBottomGradient,
  DictionaryEllipsoidalGradient,
  Image20,
  Hospitals,
  Study,
  SOS,
  SAMU,
  Image26,
  WrapperFooter,
  Footer,
  FooterSection,
  Logo,
  Space,
} from "./styles";

function Home() {
  const [download, setDownload] = useState(false);

  return (
    <Container>
      <Center>
        <Header download={download} setDownload={setDownload} />

        <ImageBackground>
          <Text weight={900} size={32}>
            Use esse aplicativo durante
          </Text>
          <Text weight={900} size={32}>
            as emergências médicas em odontologia.
          </Text>

          <Text size={20} marginTop={20}>
            E também como uma ferramenta didática.
          </Text>

          <WrapperButton>
            <GradientButton onClick={() => setDownload(true)}>
              <Text size={18} color={colors.white} weight={700}>
                Baixe o app
              </Text>
            </GradientButton>
          </WrapperButton>

          <Space />
          <WrapperNetworks>
            <Social />

            <NetworksText>
              <Text size={16} weight={700}>
                Nos siga nas redes sociais
              </Text>
              <Text size={16} weight={700}>
                e fique por dentro das novidades.
              </Text>
            </NetworksText>
          </WrapperNetworks>
        </ImageBackground>

        <Parallax
          renderLayer={(percentage) => {
            return (
              <>
                <BackgroundTop>
                  <WhiteCircle
                    style={{
                      top: screenWidth(
                        percentage > 1.18 ? 126 : percentage * 200 - 100
                      ),
                    }}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image3}
                    alt="Celular 1"
                    width={550}
                    top={0}
                    left={360}
                    startAnimation={0.82}
                    strength={0}
                    resetPercentage={0.66}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image5}
                    alt="Celular 2"
                    width={550}
                    top={100}
                    left={160}
                    startAnimation={0.86}
                    strength={0}
                    resetPercentage={0.66}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image4}
                    alt="Celular 3"
                    width={550}
                    top={110}
                    left={640}
                    startAnimation={0.88}
                    strength={0}
                    resetPercentage={0.66}
                  />
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image39}
                    alt="Celular 4"
                    width={550}
                    top={400}
                    left={480}
                    startAnimation={0.99}
                    strength={0}
                    resetPercentage={0.66}
                  />
                </BackgroundTop>
                <BackgroundBottom>
                  <Image7>
                    <AnimatedText
                      percentage={percentage}
                      titles={[
                        "Aprenda as manobras de",
                        "emergências mais comuns",
                        "no consultório.",
                      ]}
                      texts={[
                        "Esteja familiarizado com o conteúdo do app",
                        "desde a graduação até a sua prática clínica.",
                      ]}
                      startAnimation={1.37}
                      resetPercentage={1.17}
                    />
                  </Image7>
                  <AnimatedImage
                    percentage={percentage}
                    src={Images.Image2}
                    alt="Celular 5"
                    width={550}
                    top={500}
                    left={280}
                    startAnimation={1.03}
                    strength={0}
                    resetPercentage={0.66}
                  />
                </BackgroundBottom>
              </>
            );
          }}
        />

        <Parallax
          renderLayer={(percentage) => {
            return (
              <Calculator>
                <div>
                  <AnimatedText
                    percentage={percentage}
                    titles={["Resultados rápidos com", "nossas calculadoras"]}
                    texts={[
                      "Calcule rapidamente a glicemia e a pressão arterial",
                      "sistêmica de seu paciente, estude também pelas",
                      "tabelas de referência.",
                    ]}
                    startAnimation={0.85}
                    resetPercentage={0.71}
                  />
                </div>

                <AnimatedImage
                  percentage={percentage}
                  src={Images.Group96}
                  atl="Group 96"
                  top={100}
                  left={850}
                  startAnimation={0.68}
                  strength={800}
                  resetPercentage={0.71}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Group95}
                  atl="Group 95"
                  top={220}
                  left={680}
                  startAnimation={0.73}
                  strength={800}
                  resetPercentage={0.71}
                />

                <AnimatedImage
                  percentage={percentage}
                  src={Images.Image10}
                  alt="Paper 1"
                  width={180}
                  top={760}
                  left={540}
                  startAnimation={1.14}
                  strength={-300}
                  resetPercentage={1.02}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Image11}
                  alt="Paper 2"
                  width={110}
                  top={680}
                  left={730}
                  startAnimation={1.14}
                  strength={-140}
                  resetPercentage={1.02}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Image12}
                  alt="Paper 3"
                  width={80}
                  top={650}
                  left={840}
                  startAnimation={1.14}
                  strength={-50}
                  resetPercentage={1.02}
                />
              </Calculator>
            );
          }}
        >
          <Parallax
            renderLayer={(percentage) => {
              return (
                <Image7>
                  <Group89 src={Images.Group89} />
                  <AnimatedText
                    percentage={percentage}
                    titles={[
                      "Tenha uma condução das",
                      "sequências das manobras.",
                    ]}
                    texts={[
                      "E avalie a condição clínica do seu",
                      "paciente, sua responsividade e seus",
                      "sinais vitais.",
                    ]}
                    startAnimation={0.6}
                    resetPercentage={0.49}
                  />

                  <OpacityText
                    first
                    percentage={percentage}
                    startAnimation={0.9}
                    resetPercentage={0.49}
                  />
                  <OpacityText
                    percentage={percentage}
                    src={Images.Share}
                    alt="Share"
                    text="Compartilhe todo o conteúdo do app com seus colegas em outros aplicativos."
                    startAnimation={0.97}
                    resetPercentage={0.49}
                  />
                  <OpacityText
                    percentage={percentage}
                    src={Images.Star}
                    alt="Star"
                    text="Salve as informações mais relevantes para você e reveja quando quiser."
                    startAnimation={1.04}
                    resetPercentage={0.49}
                  />
                </Image7>
              );
            }}
          >
            <Parallax
              renderLayer={(percentage) => {
                return (
                  <Cards>
                    <Image13 src={Images.Image13} />
                    <AnimatedImage
                      horizontal
                      percentage={percentage}
                      src={Images.Group85}
                      alt="Group 85"
                      width={500}
                      top={180}
                      left={215}
                      startAnimation={0.38}
                      strength={585}
                      withoutOpacity
                      resetPercentage={0.01}
                    />
                    <CardsGradient />
                    <WrapperAnimatedText>
                      <AnimatedText
                        percentage={percentage}
                        titles={[
                          "Uma sequência das",
                          "emergências mais",
                          "comuns do dia a dia.",
                        ]}
                        texts={[
                          "Saiba como desengasgar um adulto, criança",
                          "ou bebê e chame o serviço de emergência",
                          "direto pelo app.",
                        ]}
                        startAnimation={0.28}
                        resetPercentage={0.01}
                      />
                    </WrapperAnimatedText>
                  </Cards>
                );
              }}
            />
          </Parallax>
        </Parallax>

        <Parallax
          renderLayer={(percentage) => {
            return (
              <Medicaments>
                <Image15 src={Images.Image15} />
                <AnimatedText
                  percentage={percentage}
                  titles={[
                    "Seja notificado do vencimento",
                    "dos medicamentos da caixa",
                    "de emergência.",
                  ]}
                  texts={[
                    "Registre seus medicamentos e receba mensagens",
                    "de alerta automaticamente e já agendadas em seu",
                    "aparelho.",
                  ]}
                  startAnimation={0.33}
                  resetPercentage={0.01}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Pill}
                  alt="Pill"
                  width={70}
                  top={180}
                  left={675}
                  startAnimation={0.8}
                  strength={-70}
                  withoutOpacity
                  resetPercentage={0.01}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Tablet}
                  alt="Tablet"
                  width={30}
                  top={330}
                  left={890}
                  startAnimation={0.8}
                  strength={-100}
                  withoutOpacity
                  resetPercentage={0.01}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Circle}
                  alt="Circle"
                  width={30}
                  top={235}
                  left={930}
                  startAnimation={0.8}
                  strength={-50}
                  withoutOpacity
                  resetPercentage={0.01}
                />
              </Medicaments>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.quaternary.blue }}
          renderLayer={(percentage) => {
            return (
              <Dictionary>
                <DictionaryTopGradient />
                <DictionaryBottomGradient />
                <DictionaryEllipsoidalGradient left src={Icons.Ellipse} />
                <DictionaryEllipsoidalGradient src={Icons.Ellipse} />
                <Image20 src={Images.Image20} alt="Image 20" />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.C}
                  alt="C"
                  width={70}
                  top={160}
                  left={150}
                  startAnimation={0.9}
                  strength={-60}
                  withoutOpacity
                  resetPercentage={0.36}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.M}
                  alt="M"
                  width={70}
                  top={450}
                  left={195}
                  startAnimation={1}
                  strength={-80}
                  withoutOpacity
                  resetPercentage={0.36}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.D}
                  alt="D"
                  width={70}
                  top={220}
                  left={465}
                  startAnimation={1}
                  strength={-60}
                  withoutOpacity
                  resetPercentage={0.36}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.G}
                  alt="G"
                  width={70}
                  top={500}
                  left={550}
                  startAnimation={0.9}
                  strength={-120}
                  withoutOpacity
                  resetPercentage={0.36}
                />
                <AnimatedText
                  percentage={percentage}
                  titles={[
                    "Glossário com termos técnicos",
                    "em medicina abordados",
                    "nas manobras.",
                  ]}
                  texts={[
                    "Pesquise sem precisar sair do aplicativo, tenha tudo o que",
                    "precisar em um único lugar.",
                  ]}
                  startAnimation={0.66}
                  resetPercentage={0.36}
                />
              </Dictionary>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.white }}
          bgImage={Images.Image21}
          bgImageAlt="Hospitals"
          bgImageStyle={{
            width: window.outerWidth * 1.39,
            height: ((window.outerWidth * 1.39) / 2613) * 962,
            marginLeft: -screenWidth(230),
            marginTop: screenWidth(257),
          }}
          strength={0}
          renderLayer={(percentage) => {
            return (
              <Hospitals>
                <Text weight={900} size={32}>
                  Localize os hospitais
                </Text>
                <Text weight={900} size={32}>
                  próximos a você.
                </Text>
                <Text size={20}>
                  Tenha uma rota para guia-lo diretamente pelo próprio
                </Text>
                <Text size={20}>Google Maps® em seu aparelho.</Text>

                <Hospital
                  percentage={percentage}
                  top={185}
                  left={610}
                  startAnimation={0.7}
                  resetPercentage={0.46}
                />
                <Hospital
                  percentage={percentage}
                  top={220}
                  left={650}
                  startAnimation={0.76}
                  resetPercentage={0.46}
                />
                <Hospital
                  percentage={percentage}
                  top={285}
                  left={20}
                  startAnimation={0.82}
                  resetPercentage={0.46}
                />
                <Hospital
                  percentage={percentage}
                  top={320}
                  left={265}
                  startAnimation={0.87}
                  resetPercentage={0.46}
                />
                <Hospital
                  percentage={percentage}
                  top={420}
                  left={1185}
                  startAnimation={0.93}
                  resetPercentage={0.46}
                />
                <Hospital
                  percentage={percentage}
                  top={435}
                  left={1050}
                  startAnimation={0.98}
                  resetPercentage={0.46}
                />
                <Hospital
                  percentage={percentage}
                  top={485}
                  left={860}
                  startAnimation={1.04}
                  resetPercentage={0.46}
                />
                <Hospital
                  percentage={percentage}
                  top={545}
                  left={300}
                  startAnimation={1.1}
                  resetPercentage={0.46}
                />
                <Hospital
                  percentage={percentage}
                  top={675}
                  left={1190}
                  startAnimation={1.26}
                  resetPercentage={0.46}
                />
                <Hospital
                  percentage={percentage}
                  top={755}
                  left={1090}
                  startAnimation={1.32}
                  resetPercentage={0.46}
                />
              </Hospitals>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.white }}
          strength={0}
          renderLayer={(percentage) => {
            return (
              <Study>
                <AnimatedImage
                  horizontal
                  percentage={percentage}
                  src={Images.Image24}
                  atl="Image 24"
                  width={900}
                  top={37}
                  left={-50}
                  startAnimation={0.32}
                  strength={-850}
                  resetPercentage={0.19}
                />
                <AnimatedImageRight
                  horizontal
                  percentage={percentage}
                  src={Images.Image23}
                  atl="Image 23"
                  width={850}
                  top={0}
                  right={0}
                  startAnimation={0.32}
                  strength={-850}
                  resetPercentage={0.19}
                />

                <Text weight={900} size={32}>
                  Estude pelo aplicativo
                </Text>
                <Text weight={900} size={32}>
                  de qualquer lugar.
                </Text>
                <Text size={20}>Uma ferramenta de ensino para estudantes</Text>
                <Text size={20}>e profissionais da área da saúde.</Text>
              </Study>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.white }}
          strength={0}
          renderLayer={(percentage) => {
            return (
              <SOS>
                <Text weight={900} size={32}>
                  Cadastre contatos de emergência
                </Text>
                <Text weight={900} size={32}>
                  para pedidos de S.O.S.
                </Text>
                <Text size={20}>
                  Dispare suas mensagens de emergência diretamente
                </Text>
                <Text size={20}>
                  para o Whatsapp® dos contatos que você cadastrar.
                </Text>

                <AnimatedImageRight
                  horizontal
                  percentage={percentage}
                  src={Images.Image25}
                  atl="Image 25"
                  width={850}
                  top={0}
                  right={0}
                  startAnimation={0.12}
                  strength={-850}
                  resetPercentage={0.18}
                />
                <AnimatedImage
                  percentage={percentage}
                  src={Images.Image40}
                  atl="Image 40"
                  top={180}
                  left={100}
                  startAnimation={0.47}
                  strength={-200}
                  resetPercentage={0.18}
                />
                <AnimatedImage
                  percentage={percentage}
                  startAnimation={0.47}
                  resetPercentage={0.18}
                />
                <Arrow
                  percentage={percentage}
                  startAnimation={0.77}
                  resetPercentage={0.18}
                />
              </SOS>
            );
          }}
        />

        <Parallax
          style={{ backgroundColor: colors.white }}
          strength={0}
          renderLayer={(percentage) => {
            return (
              <SAMU>
                <AnimatedText
                  percentage={percentage}
                  titles={[
                    "Acione rapidamente um",
                    "serviço de atendimento",
                    "emergencial.",
                  ]}
                  texts={[
                    "Para as cidades que não possuem o serviço",
                    "do SAMU, acione o 193 direto pelo app.",
                  ]}
                  startAnimation={0.22}
                  resetPercentage={0.35}
                />

                <AnimatedImageRight
                  horizontal
                  percentage={percentage}
                  src={Images.Image28}
                  atl="Image 28"
                  width={530}
                  top={-60}
                  right={300}
                  startAnimation={0.46}
                  strength={-400}
                  resetPercentage={0.35}
                />

                <Image26 src={Images.Image26} atl="Image 26" />

                <AnimatedImageRight
                  horizontal
                  percentage={percentage}
                  src={Images.Image29}
                  atl="Image 29"
                  width={700}
                  top={-50}
                  right={-30}
                  startAnimation={0.6}
                  strength={-400}
                  resetPercentage={0.35}
                />

                <WrapperFooter>
                  <Footer>
                    <Space />

                    <FooterSection>
                      <Logo src={Images.Logo} />

                      <Text size={16}>Uma tecnologia social acadêmica</Text>

                      <Space />

                      <Text size={12} color={colors.primary.gray}>
                        Copyright © 2021 Odonto Guardians. Todos os direitos
                        reservados. App Odonto Guardians CNPJ 37.466.354/0001-36
                      </Text>
                      <Text size={12} color={colors.primary.gray}>
                        Aplicativo desenvolvido em parceria com a Universidade
                        Federal do Rio de Janeiro.
                      </Text>
                    </FooterSection>

                    <Social />
                  </Footer>
                </WrapperFooter>
              </SAMU>
            );
          }}
        />
      </Center>
    </Container>
  );
}

export default Home;
