import styled from "styled-components";
import { Icons } from "~/assets/icons";
import colors from "~/styles/colors";
import { screenWidth } from "~/config/dimensions";
import { Images } from "~/assets/images";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.secondary.blue};
  display: flex;
  justify-content: center;
`;

export const Center = styled.div`
  width: ${window.outerWidth}px;
`;

export const ImageBackground = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${screenWidth(116)}px;
  background-image: url("${Icons.Rectangle40}");
  background-position-x: center;
  background-size: 50%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
`;

export const WrapperButton = styled.div`
  margin: ${screenWidth(60)}px;
`;

export const WrapperNetworks = styled.div`
  display: flex;
  margin: ${screenWidth(30)}px;
`;

export const NetworksText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: ${screenWidth(2)}px solid ${colors.primary.blue};
  margin-left: ${screenWidth(10)}px;
  padding-left: ${screenWidth(10)}px;
`;

export const BackgroundTop = styled.div`
  background-image: url("${Images.Image1}");
  /* background-position-y: ${screenWidth(130)}px; */
  background-size: ${window.outerWidth}px;
  background-repeat: no-repeat;
  width: ${window.outerWidth}px;
  height: ${(window.outerWidth / 1920) * 1083}px;
  margin-top: ${screenWidth(130)}px;
`;

export const BackgroundBottom = styled.div`
  background-image: url("${Images.Image7}");
  background-position-x: ${-screenWidth(150)}px;
  background-position-y: ${-screenWidth(70)}px;
  background-size: ${window.outerWidth}px;
  background-repeat: no-repeat;
  width: ${window.outerWidth}px;
  height: ${(window.outerWidth / 1664) * 1110}px;
`;

export const WhiteCircle = styled.div`
  background-color: ${colors.white};
  border-radius: ${screenWidth(1000)}px;
  position: absolute;
  width: ${screenWidth(750)}px;
  height: ${screenWidth(750)}px;
  top: ${screenWidth(150)}px;
  left: ${screenWidth(150)}px;
`;

export const Image7 = styled.div`
  height: ${screenWidth(830)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: ${screenWidth(85)}px;
`;

export const Calculator = styled.div`
  height: ${screenWidth(700)}px;
  display: flex;
  align-items: center;
`;

export const Group89 = styled.img`
  position: absolute;
  width: ${screenWidth(1000)}px;
  top: ${screenWidth(200)}px;
  left: -${screenWidth(245)}px;
`;

export const Cards = styled.div`
  height: ${screenWidth(500)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const Image13 = styled.img`
  position: absolute;
  width: ${screenWidth(400)}px;
  top: 0px;
  left: ${screenWidth(80)}px;
`;

export const CardsGradient = styled.div`
  position: absolute;
  top: 0px;
  right: ${screenWidth(670)}px;
  width: ${screenWidth(80)}px;
  height: ${screenWidth(500)}px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.001) 0%,
    ${colors.secondary.blue} 100%
  );
`;

export const WrapperAnimatedText = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: ${screenWidth(670)}px;
  height: ${screenWidth(500)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-color: ${colors.secondary.blue};
`;

export const Medicaments = styled.div`
  height: ${screenWidth(600)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Image15 = styled.img`
  position: absolute;
  top: 0px;
  left: ${screenWidth(630)}px;
  width: ${screenWidth(600)}px;
`;

export const Dictionary = styled.div`
  height: ${screenWidth(800)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const DictionaryTopGradient = styled.div`
  position: absolute;
  top: 0px;
  width: ${window.outerWidth}px;
  height: ${screenWidth(100)}px;
  background: linear-gradient(
    0deg,
    ${colors.quaternary.blue} 0%,
    ${colors.secondary.blue} 100%
  );
`;

export const DictionaryBottomGradient = styled.div`
  position: absolute;
  bottom: 0px;
  width: ${window.outerWidth}px;
  height: ${screenWidth(150)}px;
  background: linear-gradient(
    0deg,
    ${colors.white} 0%,
    ${colors.tertiary.blue} 100%
  );
`;

export const DictionaryEllipsoidalGradient = styled.img`
  position: absolute;
  bottom: -${screenWidth(360)}px;
  left: ${(props) => (props.left ? `${-screenWidth(300)}px` : "none")};
  right: -${screenWidth(300)}px;
  width: ${screenWidth(500)}px;
  height: ${screenWidth(700)}px;
`;

export const Image20 = styled.img`
  position: absolute;
  top: ${screenWidth(200)}px;
  left: ${screenWidth(80)}px;
  width: ${screenWidth(600)}px;
`;

export const Hospitals = styled.div`
  height: ${screenWidth(950)}px;
  display: flex;
  flex-direction: column;
  padding-top: ${screenWidth(75)}px;
  align-items: center;
`;

export const Study = styled.div`
  height: ${screenWidth(700)}px;
  display: flex;
  flex-direction: column;
  padding-top: ${screenWidth(120)}px;
  align-items: center;
`;

export const SOS = styled.div`
  height: ${screenWidth(700)}px;
  display: flex;
  flex-direction: column;
  padding: ${screenWidth(50)}px ${screenWidth(150)}px;
`;

export const SAMU = styled.div`
  height: ${screenWidth(900)}px;
  display: flex;
  flex-direction: column;
  padding: ${screenWidth(50)}px;
`;

export const Image26 = styled.img`
  position: absolute;
  top: ${screenWidth(90)}px;
  right: ${screenWidth(500)}px;
  width: ${screenWidth(420)}px;
`;

export const WrapperFooter = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  border-top: 1px solid ${colors.secondary.gray};
  margin: 0px ${screenWidth(100)}px;
  padding-top: ${screenWidth(20)}px;
`;

export const FooterSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: ${screenWidth(150)}px;
  margin-bottom: ${screenWidth(10)}px;
`;

export const Space = styled.div`
  width: ${screenWidth(220)}px;
  height: ${screenWidth(20)}px;
`;
