import React from "react";

import { Images } from "~/assets/images";
import AnimatedText from "~/components/AnimatedText";
import { GradientButton } from "~/components/GradientButton";
import MobileHeader from "~/components/MobileHeader";
import { Text } from "~/components/Text/";
import colors from "~/styles/colors";
import AnimatedImageRight from "~/components/AnimatedImageRight";

import {
  Container,
  Center,
  WrapperAnimatedText,
  WrapperButton,
} from "./styles";

function Contact() {
  return (
    <Container>
      <Center>
        <MobileHeader />

        <WrapperAnimatedText>
          <AnimatedText
            percentage={1}
            titles={["Fale conosco"]}
            texts={[
              "Utilize o botão abaixo para enviar",
              "sua mensagem.",
              "Teremos o maior prazer em",
              "atendê-lo",
            ]}
            startAnimation={0}
            resetPercentage={2}
            withoutMargin
            textAlign="center"
            textWeight={500}
          />
        </WrapperAnimatedText>

        <WrapperButton href="mailto:odontoguardiansapp@gmail.com">
          <GradientButton>
            <Text size={18} color={colors.white} weight={700}>
              Enviar email
            </Text>
          </GradientButton>
        </WrapperButton>

        <AnimatedImageRight
          horizontal
          percentage={1}
          src={Images.Image23}
          atl="Image 23"
          width={500}
          top={460}
          right={0}
          startAnimation={0}
          strength={-500}
          resetPercentage={2}
        />
      </Center>
    </Container>
  );
}

export default Contact;
