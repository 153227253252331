import React, { useEffect, useState } from "react";
import { arrayOf, number, string, bool } from "prop-types";

import { Text } from "~/components/Text";
import { screenWidth } from "~/config/dimensions";

import { Space } from "./styles";

function AnimatedText({
  percentage,
  titles,
  texts,
  startAnimation,
  resetPercentage,
  withoutMargin,
  textAlign,
  textWeight,
}) {
  const renderedTitles = [];
  const renderedTexts = [];
  const [animation, setAnimation] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (percentage <= resetPercentage) {
      setAnimation(0);
      setActive(false);
    }
    if (percentage >= startAnimation) {
      setActive(true);
    }
  }, [percentage]);

  useEffect(() => {
    let interval = null;
    if (active && animation < 1.7) {
      interval = setInterval(() => {
        setAnimation((currentAnimation) => currentAnimation + 0.01);
      }, 6);
    } else if (animation === 0) {
      clearInterval(interval);
    } else if (animation === 1.7) {
      setAnimation(1.7);
      setActive(false);
    }
    return () => clearInterval(interval);
  }, [active, animation]);

  titles.forEach((element) =>
    renderedTitles.push(
      <Text
        textAlign={textAlign}
        style={{
          opacity: animation > 1 ? 1 : animation,
          marginRight: screenWidth(
            (withoutMargin ? 0 : animation > 1 ? 1 : animation) * 100
          ),
          marginLeft: screenWidth(
            (withoutMargin ? 0 : animation > 1 ? 1 : animation) * 100
          ),
        }}
        weight={700}
        size={32}
      >
        {element}
      </Text>
    )
  );

  texts.forEach((element) =>
    renderedTexts.push(
      <Text
        textAlign={textAlign}
        weight={textWeight}
        style={{
          opacity: animation < 0.7 ? 0 : animation - 0.7,
          marginRight: screenWidth(
            withoutMargin ? 0 : (animation < 0.7 ? 0 : animation - 0.7) * 100
          ),
          marginLeft: screenWidth(
            withoutMargin ? 0 : (animation < 0.7 ? 0 : animation - 0.7) * 100
          ),
        }}
        size={20}
      >
        {element}
      </Text>
    )
  );

  return (
    <>
      {renderedTitles}
      <Space />
      {renderedTexts}
    </>
  );
}

AnimatedText.propTypes = {
  percentage: number,
  titles: arrayOf(string),
  texts: arrayOf(string),
  startAnimation: number,
  resetPercentage: number,
  withoutMargin: bool,
  textAlign: string,
  textWeight: number,
};

AnimatedText.defaultProps = {
  percentage: null,
  titles: [],
  texts: [],
  startAnimation: null,
  resetPercentage: null,
  withoutMargin: false,
  textAlign: "left",
  textWeight: 300,
};

export default AnimatedText;
